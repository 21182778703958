import React, { useEffect, useMemo, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import { useIntl } from 'react-intl';

import StepTitle from '../../components/StepTitle';
import SignaturePad from '../../components/fields/SignaturePad';
import StepNavigation from '../../components/StepNavigation';
import Card from '../../components/Card';
import SubTitleWithIcon from '../PersonalInfo/SubTitleWithIcon';
import UserIcon from '../../assets/icons/user-icon.inline.svg';
import Button from '../../components/Button';
import { useLocalStorageState } from '../../hooks/useLocalStorageState';
import { PRODUCT_TYPE } from '../../services/appLocalStorage';
import {
  useGlobalState,
  useJsonApi,
} from '../../services/GlobalStore/GlobalStore';
import useDataLayer from '../../hooks/useDataLayer';
// import { useHandleError } from '../../services/api';
import { clearPart1Data } from '../../services/resources/claims';
import { updatePerson } from '../../services/resources/persons';
import { PassengerModel } from '../../models/PassengerModel';
import { steps } from '../../utils/routes';
import messages from './messages';
import { ClaimModel } from '../../models/ClaimModel';
import PermissionDescription from './PermissionDescription';
import InfoOverlay from '../../components/InfoOverlay';
import { atomicOperationSubmitClaim } from '../../services/resources/submitClaim';
import { jsonApiAxios, getResponseErrors } from '../../services/api';
import useAuthenticate from '../../hooks/useAuthenticate';
import Alert from '../../components/Alert';

const validationSchema = yup.object().shape({
  signature: yup.string().required(),
});

/**
 * This is used when signing a new claim, or signing docs by fellow passengers
 */
// eslint-disable-next-line react/no-multi-comp
const SignPermission = ({
  navigateBack,
  navigateNext,
  claimId,
  passengerId,
  withNavigation = false,
}: {
  navigateBack?: () => void;
  navigateNext: (claim: ClaimModel) => void;
  claimId?: string;
  passengerId?: string;
  withNavigation?: boolean;
}) => {
  const { formatMessage } = useIntl();
  const [pushEvent] = useDataLayer();
  const [showAlert, setShowAlert] = useState(false);
  const [loginErrors, setLoginErrors] = useState<null | string[]>(null);
  const [personalInfo = {}] = useLocalStorageState('personalInfo');
  const [customerFlights] = useGlobalState('customerFlights.booked');
  const [alternativeFlights] = useGlobalState('customerFlights.alternative');
  const [placeOfJurisdiction] = useLocalStorageState('placeOfJurisdiction');
  const [lettersToSign = []] = useLocalStorageState('lettersToSign');
  // const [cobrandingDossierTokenId] = useLocalStorageState(
  //   'cobrandingDossierTokenId'
  // );
  // const cobrandingDossierToken = useBuild(
  //   'cobrandingDossierTokens',
  //   cobrandingDossierTokenId
  // );
  const [productType] = useLocalStorageState(PRODUCT_TYPE);
  const { isLoggedIn, doLogin } = useAuthenticate();

  // const [handleError] = useHandleError();

  const { data: dbClaim, get: getDBClaim } = useJsonApi(`/claims/${claimId}`, {
    credentials: 'include',
    cachePolicy: 'no-cache',
    queryParams: {
      include: [
        'passengers',
        'passengers.person',
        'placesOfJurisdiction',
      ].join(),
    },
  });

  const { data: dbPassenger, get: getDBPassenger } = useJsonApi(
    `/passengers/${passengerId}`,
    {
      credentials: 'include',
      cachePolicy: 'no-cache',
      queryParams: {
        include: 'person',
      },
    }
  );

  useEffect(() => {
    if (claimId) {
      getDBClaim();
    }
    if (passengerId) {
      getDBPassenger();
    }
    // eslint-disable-next-line
  }, [claimId, passengerId]);

  /**
   * Passenger data can be retrieved from 3 places for this component:
   *  1. When we sign docs for fellow passengers - claim and passenger exist
   *  2. When we came back from funnel and we have claim - we take main booker
   *  3. When claim not yet created we get info from local storage
   */
  const passenger = useMemo(() => {
    if (dbPassenger?.[0]?.id) return dbPassenger?.[0];
    if (dbClaim?.[0]?.passengers?.length)
      return dbClaim?.[0]?.passengers.find(
        (p: PassengerModel) => p.main_booker === true
      );
    return personalInfo;
  }, [dbClaim, dbPassenger, personalInfo]);

  function handleSubmit(values, { setSubmitting }) {
    const { signature } = values;

    if (claimId) {
      updatePerson({
        personId: passenger?.person?.id,
        values: {
          signature,
        },
      }).then(() => {
        navigateNext(dbClaim);
      });
    } else {
      const requestValues = atomicOperationSubmitClaim(
        customerFlights,
        alternativeFlights,
        signature,
        isLoggedIn
      );

      jsonApiAxios
        .post('/operations', requestValues, {
          noSerialize: true,
        })
        .then(async res => {
          const dataResponse = res?.data['atomic:results'];
          if (dataResponse?.length) {
            if (!isLoggedIn) {
              try {
                const { status } = await jsonApiAxios.post(
                  '/register',
                  {
                    email: personalInfo?.email,
                    password: personalInfo?.password,
                  },
                  {
                    schema: {
                      attributes: ['email', 'password'],
                    },
                    type: 'customers',
                  }
                );
                if (status === 200) {
                  doLogin();
                }
              } catch (error) {
                if (error?.response) {
                  setLoginErrors(getResponseErrors(error));
                  return;
                }
              }
            }
            const claim = dataResponse.find(
              (item: { data: { type: string } }) =>
                item?.data?.type === 'claims'
            );

            localStorage.setItem('yo:claimId', claim?.data?.id);
            if (process.env.NODE_ENV !== 'test') {
              const customerEmail = JSON.parse(
                localStorage.getItem('yo:compensationCheck')
              )?.value?.email;

              pushEvent({
                eventLabel: 'continue',
                eventAction: 'permission',
                customerEmail,
              });
            }

            clearPart1Data();
            const data = prepareData(claim?.data);
            navigateNext?.(data);
          }
        })
        .catch(() => {
          setShowAlert(true);
          setSubmitting(false);
        });
    }
  }

  const prepareData = (data: any) => {
    return {
      id: data?.id,
      meta: data?.meta,
      ...data?.attributes,
    };
  };

  const passengerFullName = passenger?.person
    ? passenger.person?.first_name + ' ' + passenger.person?.last_name
    : passenger?.firstname + ' ' + passenger?.lastname;

  return (
    <Formik
      initialValues={{ signature: null }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validateOnMount={true}
    >
      {({ isValid }) => (
        <Form role="form">
          <Card onlyDesktop={true}>
            <div className="flex justify-center sm:justify-between items-center text-center">
              <StepTitle className="sm:mb-0 mb-35">
                {formatMessage(messages.permissionTitle)}
              </StepTitle>
              <InfoOverlay
                title={messages.permissionTitle}
                description={messages.asideText}
              />
            </div>
            <Card onlyMobile={true}>
              <SubTitleWithIcon
                as="p"
                icon={UserIcon}
                className="fill-primary text-gray-300 mb-10 mt-0"
              >
                <span
                  data-testid="passenger-name"
                  className="font-bold font-pt-sans text-gray-400"
                >
                  {passengerFullName}
                </span>
              </SubTitleWithIcon>
              <Field name="signature" component={SignaturePad} />
              {withNavigation ? null : (
                <div className="flex justify-center mt-20">
                  <Button theme="primary" type="submit" disabled={!isValid}>
                    {formatMessage(messages.saveSignatureButton)}
                  </Button>
                </div>
              )}
            </Card>
            <PermissionDescription
              placeOfJurisdiction={placeOfJurisdiction}
              lettersToSign={lettersToSign}
              productType={dbClaim?.product_type || productType}
            />
            {showAlert && (
              <Alert
                level="danger"
                className="w-full my-20"
                withClose={true}
                // eslint-disable-next-line react/jsx-no-bind
                setCloseIcon={() => setShowAlert(false)}
                iconClassName="cursor-pointer"
                closeIconStyle={{ marginLeft: '45%' }}
              >
                {formatMessage(messages.dangerMessage)}
              </Alert>
            )}
            {loginErrors && loginErrors.length > 0
              ? loginErrors.map((error, index) => (
                  <Alert
                    // eslint-disable-next-line
                    key={`error-${index}`}
                    level="danger"
                    className="w-full my-20"
                  >
                    {error}
                  </Alert>
                ))
              : null}
          </Card>
          {withNavigation ? (
            <StepNavigation
              onBackClick={navigateBack}
              backButtonMessage={formatMessage(messages.permissionBackButton)}
              submitButtonMessage={formatMessage(messages.submitContinue)}
              dataLayerPushOnBack={true}
              dataLayerPushOnContinue={true}
              step={steps.Permission}
            />
          ) : null}
        </Form>
      )}
    </Formik>
  );
};

export default SignPermission;
